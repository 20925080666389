import {AddContacts} from 'components/icons/addcontacts'
import {Loading} from 'components/loading'
import { RasaContext } from 'context'
import React from 'react'
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row} from 'reactstrap'
import * as GenericRedux from '../../generic/genericRedux'
import { RasaReactComponent } from '../../generic/rasaReactComponent'
import './styles.css'

const initialState =  {
  email: '',
  first_name: '',
  isSaving: false,
  last_name: '',
  validate: {
    email: 'invalid',
    first_name: 'invalid',
    last_name: 'invalid',
  },
}

interface AddSubscriberProps {
  push: any,
  isOpen: boolean,
}

export class AddSubscriberComponent extends RasaReactComponent<AddSubscriberProps, any> {

  public static contextType = RasaContext;
  constructor(props: any) {
    super(props, 'rasaSubscriber', initialState)
 }

  public componentWillMount = () => {
    this.context.user.init().then(({person, activeCommunity}) => {
      this.loadRecord(activeCommunity.communityId, null)
    })
  }

  public render() {
    return this.state.isSaving ?
      <div className="add-contact-page">
        <div className="bouncing-balls">
          <Loading size="64"/>
        </div>
      </div> :
      <div className="add-contact-page">
        <div className="header-flex">
          <AddContacts svgwidth={60} svgheight={60} />
          <h2 className="header-text">Add a Contact</h2>
        </div>
        <div className="contact-form">
        <Form>
          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label for="first-name">First Name</Label>
                <Input type="text"
                        name="first_name"
                        id="first-name"
                        onChange={(e) => {this.validateName(e)
                                          this.writeInput(e)
                                          this.propertyChanged('first_name', e.target.value)}}
                          valid={this.state.validate.firstName === 'valid'}
                          invalid={this.state.validate.firstName === 'invalid'}
                          placeholder="First Name" />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="last-name">Last Name</Label>
                <Input type="text"
                        name="last_name"
                        id="last-name"
                        onChange={(e) => {this.validateName(e)
                                          this.writeInput(e)
                                          this.propertyChanged('last_name', e.target.value)}}
                          valid={this.state.validate.lastName === 'valid'}
                          invalid={this.state.validate.lastName === 'invalid'}
                          placeholder="Last Name" />
              </FormGroup>
            </Col>
          </Row>
          <Row form>
                <Label for="email">Email</Label>
                <Input type="email"
                        name="email"
                        id="email"
                        width={1000}
                        onChange={(e) => {this.validateEmail(e)
                                          this.writeInput(e)
                                          this.propertyChanged('email', e.target.value)}}
                          invalid={this.state.validate.email === 'invalid'}
                          placeholder="email@example.com" />
          </Row>
        </Form>
        </div>
          <Button disabled={this.state.validate.email === 'invalid'}
                  className="save-button"
                  onClick={() => {
                    this.saveRecord(null)
                      .then(() => {
                      const validate = {
                        email: 'invalid',
                        first_name: 'invalid',
                        last_name: 'invalid',
                      }
                      this.setState({...validate, email: '', first_name: '', last_name: ''},
                                    () => this.props.push('/contacts') )
                      })
                      .catch((err) => {
                        this.setState({isSaving: false})
                      })
                  }}>
            Add Contact
          </Button>
      </div>
  }

  private validateEmail = (e: any) => {
    const emailRex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const { validate } = this.state
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    emailRex.test(e.target.value) ? ( validate.email = 'valid') : (validate.email = 'invalid')
    this.setState({ validate })
    }

  private validateName = (e: any) => {
    const { validate } = this.state
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    e.target.value.length > 0 ? (validate[e.target.name] = 'valid') : (validate[e.target.name] = 'invalid')
    this.setState({validate})
  }

  private writeInput = (e: any) => {
    const {name, value} = e.target
    this.setState({[name]: value})
  }

  // private validateAll = (): boolean => {
  //   return Object.keys(this.state.validate).every((i: string) => {
  //     return this.state.validate[i] === 'valid'
  //   })
  // }
}

export const AddSubscriber = GenericRedux.registerNewNestedComponent<any>(AddSubscriberComponent, 'rasaSubscriber')