import { GridColumn } from '@progress/kendo-react-grid'
import { TitleCell } from 'components/content-pool/kendocells/titleCell'
import { DashboardMenuOption } from 'components/dashboard-menu/constants'
import { Article, EmailLayoutData, Image, PreviewContent, Text } from 'components/email-layout/constants'
import { SegmentationIcon } from 'components/icons/segmentation'
import { RasaContext } from 'context'
import * as GenericRedux from 'generic/genericRedux'
import {AllComponentPropsWithModal} from 'generic/genericRedux'
import { RasaDataGrid } from 'generic/rasaDataGrid'
import { RasaReactComponent } from 'generic/rasaReactComponent'
import React from 'react'
import { Button } from 'reactstrap'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { AddTemplateCodesModal } from './modals/templateCodes'
import './style.scss'

type EmailLayoutProps = AllComponentPropsWithModal<EmailLayoutData>
interface TemplateCodeAddtionalProps {
  partner_code?: any,
}
interface ArticlePool {
  articles?: Article[],
  boosted?: Article[],
  maxArticleCount?: number,
  maxEventCount?: number,
  maxLeadCount?: number,
  minArticleCount?: number,
  scheduled?: Article[],
  superBoosted?: Article[],
}
type WidgetsTemplateCodesComponentProps = GenericRedux.AllComponentProps<any> &
GenericRedux.AllComponentPropsWithModal<any> & TemplateCodeAddtionalProps & EmailLayoutProps
interface WidgetsTemplateCodesComponentState {
  communityRecordId: string,
  context?: any,
  selected?: any,
  validSections?: any,
  community_partner_code?: any,
  configurations?: any,
  communityId?: any,
  content: PreviewContent,
  articlesLoading: boolean,
  previewDate?: Date,
  previewImages?: Image[],
  previewTexts?: Text[],
  articlePool?: ArticlePool,
  forceReload: boolean,
}

export interface EditSectionProps {
  data: any,
  name: string,
  onChange: any,
  setSectionValid?: any,
  isDirty?: boolean,
  setSegmentCode?: any,
}

class WidgetsTemplateCodesComponent extends RasaReactComponent<WidgetsTemplateCodesComponentProps,
      WidgetsTemplateCodesComponentState> {
  public static contextType = RasaContext
  private sharedStore: SharedStore

  constructor(props: WidgetsTemplateCodesComponentProps) {
    super(props, 'widgetsLayout', {
      articlePool: {},
      articlesLoading: false,
      communityRecordId: '',
      selected: null,
      validSections: {},
      community_partner_code: null,
      content: {
        articles: [],
        events: [],
      },
      forceReload: false,
    })
    this.setSegmentCode = this.setSegmentCode.bind(this)
  }

  public componentDidMount = () => {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
      this.sharedStore.getValue(SharedKeys.hash),
    ])
    .then(([activeCommunity, hash]) => {
      this.loadRecord(activeCommunity.communityId, activeCommunity.data.community_id)
      .then(() => {
        this.setState({
          communityId: activeCommunity.data.community_id,
          communityRecordId: activeCommunity.communityId,
          configurations: this.props.data,
          community_partner_code: this.props.partner_code,
        }, () => {
          this.setSegmentCode(this.props.partner_code)
        })
      })
    })
  }

  public render() {
    return (
    <div className="segmentation-page">
      <div className="header-wrapper">
          <div className="column1">
            <h4 className="header-title">Segments</h4>
            <SegmentationIcon svgwidth={64.4} svgheight={45.5}/>
            <p className="header-text">
            </p>
          </div>
          <div className="column2">
            <Button
              onClick={() => this.props.openModal(AddTemplateCodesModal.key, null)}>
              + Segment
            </Button>
          </div>
        </div>
        <RasaDataGrid
              // Rasa Properties for entity, datasetName etc
              entityName=""
              datasetName="communityPartner"
              editDefaultState={true}
              forceReload={this.state.forceReload}
              // events
              sortable={false}
              pageable={false}
              data={[]}>
            <GridColumn title="Segment Code"  field="code" cell={this.ClickableSegmentCell}  width={200} />
        </RasaDataGrid>
        <div>
          <AddTemplateCodesModal
            data={this.props.modals}
            communityId={this.state.communityId}
            communityRecordId={this.state.communityRecordId}
            closeModal={this.props.closeModal}
            saveModal={this.props.saveModal}
            setSegmentCode={this.setSegmentCode}/>
        </div>
  </div>
    )
  }

  private ClickableSegmentCell = (props: any) => {
    return <TitleCell {...props} onClick={(e) => {this.openLink(e)}} />
  }
  private openLink = (p: any) => {
    const url = `${DashboardMenuOption.contacts}/?filterValue=SegmentCode&segmentCode=${p.code}`
    this.props.push(url)
  }

  private setSegmentCode = (code: any) => {
    if (code) {
      this.setState({
        community_partner_code: (code.value) ? code.value : code,
        forceReload: true,
      }, () => {
        this.setState({forceReload: false})})
      this.forceUpdate()
      this.propertyChanged('community_partner_code', code.value)
    }
  }
}

export const WidgetsTemplateCodes = GenericRedux.registerNewComponentWithModals<WidgetsTemplateCodesComponentProps>(
  WidgetsTemplateCodesComponent, 'template-codes',
  [AddTemplateCodesModal.key],
)
