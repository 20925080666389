import { UploadCSVComponent } from 'components/add-contacts/component'
import { ARCHIVE, RESUBSCRIBE, UNSUBSCRIBE } from 'components/add-contacts/constants'

import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as Router from 'router'
import './styles.css'

export class BulkUnsubscribeComponentToConnect extends Component<any, any> {
  public static contextType = RasaContext

  constructor(props: any) {
    super(props)
    this.state = {
      communityId: null,
    }
  }

  public componentDidMount() {
    this.context.user.init().then(({ person, activeCommunity }) => {
      this.setState({
        communityId: activeCommunity.communityId,
      })
    })
  }

  public render() {
    return (
      <div>
        {this.state.loading ?
          <Loading size="32"></Loading>
        :
          <div className="bulk-unsubscribe-wrapper">
            <UploadCSVComponent uploadType={UNSUBSCRIBE} push={this.props.push} ></UploadCSVComponent>
          </div>
        }
      </div>
    )
  }
}

export const BulkUnsubscribeComponent = connect(
  null,
  {
    push: Router.push,
  },
)(BulkUnsubscribeComponentToConnect)

export class BulkArchiveComponentToConnect extends Component<any, any> {
  public static contextType = RasaContext;

  constructor(props: any) {
    super(props)
    this.state = {
      communityId: null,
    }
  }

  public componentDidMount() {
    this.context.user.init().then(({ person, activeCommunity }) => {
      this.setState({
        communityId: activeCommunity.communityId,
      });
    })
  }

  public render() {
    return (
      <div>
        {this.state.loading ?
          <Loading size="32"></Loading>
        :
          <div className="bulk-archive-wrapper">
            <UploadCSVComponent uploadType={ARCHIVE} push={this.props.push} ></UploadCSVComponent>
          </div>
        }
      </div>
    )
  }
}

export const BulkArchiveComponent = connect(
  null,
  {
    push: Router.push,
  },
)(BulkArchiveComponentToConnect)

export class BulkResubscribeComponentToConnect extends Component<any, any> {
  public static contextType = RasaContext

  constructor(props: any) {
    super(props)
    this.state = {
      communityId: null,
    }
  }

  public componentDidMount() {
    this.context.user.init().then(({ person, activeCommunity }) => {
      this.setState({
        communityId: activeCommunity.communityId,
      });
    })
  }

  public render() {
    return (
      <div>
        {this.state.loading ?
          <Loading size="32"></Loading>
          :
          <div className="bulk-archive-wrapper">
            <UploadCSVComponent uploadType={RESUBSCRIBE} push={this.props.push} ></UploadCSVComponent>
          </div>
        }
      </div>
    )
  }
}

export const BulkResubscribeComponent = connect(
  null,
  {
    push: Router.push,
  },
)(BulkResubscribeComponentToConnect)