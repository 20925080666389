export interface CSVHeader {
  name: string,
  displayName?: string
}

export const csvHeaders: CSVHeader[] = [
  {name: 'id', displayName: 'ID'},
  {name: 'first_name', displayName: 'First Name'},
  {name: 'last_name', displayName: 'Last Name'},
  {name: 'email', displayName: 'Email'},
  {name: 'is_active', displayName: 'Active'},
  {name: 'is_subscribed', displayName: 'Subscribed'},
  {name: 'unsubscribe_reason', displayName: 'Unsubscribed Reason'},
  {name: 'created', displayName: 'Created'},
  {name: 'updated', displayName: 'Updated'},
  {name: 'referral_code', displayName: 'Referral Code'},
]

export enum ContactFilter {
  SELECT = 'Select',
  MODIFIED_SINCE = 'ModifiedSince',
  NEVER_CLICKED = 'NeverClicked',
  NEVER_OPENED = 'NeverOpened',
  NOT_CLICKED_SINCE = 'NotClickedSince',
  NOT_OPENED_SINCE = 'NotOpenedSince',
  UNSUBSCRIBED_BEFORE = 'UnsubscribedBefore',
  UNSUBSCRIBED_REASON = 'UnsubscribedReason',
  UNSUBSCRIBED_SINCE = 'UnsubscribedSince',
  SEGMENT_CODE = 'SegmentCode',
}

export const csvHeaderFields: string[] = csvHeaders.map((h: CSVHeader) => h.name)

export const STATUS_PARAM: string = 'status'
export const ACTIVE_STATUS: string = 'active'
export const INACTIVE_STATUS: string = 'inactive'
export const SUBSCRIBED: string = 'subscribed'
export const UNSUBSCRIBED: string = 'unsubscribed'
export const ARCHIVE: string = 'archive'

export const EXPORT_LIMIT: number = 3000

export const EXPORT_CSV_ERROR = 'Oops! There was a problem exporting your contacts. Please try again.'
export const CONSENT_CSV_IMPORT = 'ConsentCsvImport'
export const CONSENT_INTEGRATIONS_IMPORT = 'ConsentIntegrationsImport'
export const CONTACTS_ARCHIVED_SUCCESS = 'All contacts have been successfully archived'
export const CONTACTS_ARCHIVED_FAILED = 'Failed to archive contacts'
export const CONTACTS_ARCHIVED_WARNING_MESSAGE = 'If you click okay, you will archive selected contacts. Continue?'

export const MESSAGE = 'Thanks, check your email for a confirmation message'
export enum ContactSections {
  subscribed = 'contact_subscribed',
  unsubscribed = 'contact_unsubscribed',
}

export const ContactQueryFilters = {
  SEARCH_INPUT: 'searchInput',
  SEARCH_TERM: 'searchTerm',
  SECTION: 'section',
  DATE: 'date',
  SUBSCRIBED: 'subscribed',
  UNSUBSCRIBED: 'unSubscribed',
  FILTER_VALUE: 'filterValue',
  HAS_NOT_OPENED_SINCE: 'hasNotOpenedSince',
  HAS_NOT_CLICKED_SINCE: 'hasNotClickedSince',
  HAS_NEVER_CLICKED: 'hasNeverClicked',
  HAS_NEVER_OPENED: 'hasNeverOpened',
  MODIFIED_SINCE: 'modifiedSince',
  UNSUBSCRIBED_BEFORE: 'unsubscribedBefore',
  UNSUBSCRIBED_REASON: 'unsubscribedReason',
  UNSUBSCRIBED_SINCE: 'unsubscribedSince',
  FILTER_APPLIED: 'filterApplied',
  SEGMENT_CODE: 'segmentCode',
  STATUS: 'status',
}

export const ERROR_STATUS = {
  MultipleHardBounce: 'MultipleHardBounce'
}
