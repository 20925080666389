import {DashboardMenuOption} from 'components/dashboard-menu/constants'
import { AllContacts } from 'components/icons/allcontacts';
import { RasaContext } from 'context'
import pluralize from 'pluralize'
import React from 'react'
import {
  Button} from 'reactstrap'
import * as GenericRedux from '../../../generic/genericRedux'
import { RasaReactComponent } from '../../../generic/rasaReactComponent'

import { AddContacts } from 'components/icons/addcontacts'
import './_styles.scss'
import { SearchSubscribersMobile } from './subscriber-search'

interface SubscriberCounts {
  subscribed: number,
  unsubscribed: number,
  total: number,
}

interface SubscribersState {
  addSuccess: boolean
  addUserModal: boolean,
  addError: boolean,
}

type SubscriberCountsProps = GenericRedux.AllComponentPropsWithModal<SubscriberCounts>

class MobileSubscriberCountsComponent extends RasaReactComponent<SubscriberCountsProps, SubscribersState > {
  public static contextType = RasaContext;
  constructor(props: SubscriberCountsProps) {
    super(props, 'communitySubscriberCounts')

    this.state = {
      addUserModal: false,
      addSuccess: false,
      addError: false,
      isDirty: false,
      isSaving: false,
      isLoading: false,
    }

    this.addContact = this.addContact.bind(this)
    this.toggleNotification = this.toggleNotification.bind(this)
  }

  public componentDidMount = () => {
    this.context.user.init().then(({activeCommunity}) =>
      this.loadRecord(activeCommunity.communityId, activeCommunity.communityId))
  }

  public render() {
    return <div className="mobile-all-contacts-page">
            <div className="mobile-row1">
              <AllContacts svgwidth={64.4} svgheight={45.5}/>
              <h4 className="mobile-header-title">Your Contacts</h4>
              <p className="mobile-header-text">
                  You have <b>{pluralize('contact', (this.props.data.total || 0), true)} </b>
                {this.props.data.total !== this.props.data.subscribed &&
                  <span> and <span className="mobile-active-subscribers">{pluralize('subscriber',
                  (this.props.data.subscribed || 0), true)}</span></span>}
              </p>
              <div className="mobile-contactbuttons">
                <div className="mobile-add-contact-button-container">
                  <Button className="mobile-add-contact-button" onClick={this.addContact}>
                    <AddContacts svgwidth={25} svgheight={25}/>
                    <span className="white-button-text">
                        ..
                    </span>
                    <span className="button-text">
                        Add Contact
                    </span>
                  </Button>
                </div>
                <SearchSubscribersMobile/>
              </div>
            </div>
          </div>
  }

  private addContact = () => this.props.push(`${DashboardMenuOption.contacts}/add-contact`)

  private toggleNotification = () => {
    this.setState({addSuccess: !this.state.addSuccess})
    if (!this.state.addSuccess) {
      setTimeout(() => {
        this.setState({ addSuccess: false });
      }, 2000)
    }
  }
}

const initialCountsState: SubscriberCounts = {
  subscribed: 0,
  unsubscribed: 0,
  total: 0,
}

export const SubscriberCounts = GenericRedux.registerNewComponentWithModals<SubscriberCounts>(
  MobileSubscriberCountsComponent,
  'subscriber_counts',
  ['contactExportStart'],
  initialCountsState,
)
