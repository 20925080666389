import { RasaContext } from 'context'
import { Dataset, DatasetParam } from 'generic/dataset'
import React, { Component } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap'
import * as GenericRedux from '../../../generic/genericRedux'
import './_styles.scss'

import * as TypeAhead from 'react-bootstrap-typeahead'

import { DashboardMenuOption } from 'components/dashboard-menu/constants'
import { Loading } from 'components/loading'
import {debounce} from 'lodash'

type Subscriber = any

interface SubscribersGridState {
    deleteUserModal: boolean,
    searchedMembers: any[],
    communityId: string,
    isLoading: boolean,
}

export interface SubscriberProps {
    subscriberName: any,
    subscriberEmail: any,
    subscriberCreated: any,
    subscriberStatus: any,
    push: any,
}

abstract class SearchSubscribersMobileComponent extends Component<SubscriberProps, SubscribersGridState> {
    public static contextType = RasaContext;

    constructor(props: SubscriberProps, datasetParams: DatasetParam[]) {
      super(props);

      this.state = {
        deleteUserModal: false,
        searchedMembers: [],
        communityId: null,
        isLoading: true,
      }
    }

    public componentDidMount = () => {
      this.context.user.init().then(({activeCommunity}) => {
        this.setState({communityId: activeCommunity.communityId, isLoading: false})
        })
    }

    public render() {
      return this.state.isLoading ?
          <div className="add-contact-page">
            <div className="bouncing-balls">
              <Loading size="64"/>
            </div>
          </div> :
            <div className="contact-form">
            <div className="typeahead">
              <TypeAhead.AsyncTypeahead
                onSearch={debounce(this.searchMembers, 500)}
                onChange={this.selectFoundMembder}
                labelKey={(option) => `${option.first_name} ${option.last_name} - ${option.email}`}
                id="members-typeahead"
                placeholder="Find a subscriber..."
                isLoading={false}
                options={this.state.searchedMembers}/>
            </div>
          <Modal isOpen={this.state.deleteUserModal}>
            <ModalBody className="delete-modal">
              <div>
                  <h4>Are You Sure?</h4>
                  <p>Are you sure you want to delete this contact forever?</p>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button>
                Yes, delete forever.
              </Button>
              <p>Cancel </p>
            </ModalFooter>
          </Modal>
        </div>
    }

    private searchMembers = (value: string) => {
      new Dataset().loadCommunityDataset('rasaSearch', this.state.communityId, [{param: 'q', value}])
        .then((response) => {
          this.setState({searchedMembers: response[0]})
        })
        .catch((err) => {
          //WHAT DO HERE?
          throw err
        })
      }

    private selectFoundMembder = (subscriber: Subscriber) => {
      this.editSubscriber(subscriber[0])
    }

    private subscriberUrl = (s: Subscriber) => `${DashboardMenuOption.contacts}/detail?id=${s.id}`

    private editSubscriber = (s: Subscriber) => this.props.push(this.subscriberUrl(s))

  }

export const SearchSubscribersMobile = GenericRedux.registerNewNestedComponent<any>(SearchSubscribersMobileComponent, 'rasaSearch')
