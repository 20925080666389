import * as Flash from 'components/flash'
import { RasaContext } from 'context'
import { BaseClientEntity } from 'generic/baseClientEntity'
import { isEmpty } from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import * as Router from 'router'
import * as Modals from 'shared/modals'
import { DEFAULT_COMMUNITY_PARTNER_CODES } from 'shared_server_client/constants'
import { SEGMENT_CODE_CREATED_SUCCESSFULLY, SEGMENT_CODE_CREATTION_FAILED } from '../constants'

interface TemplateCodesState extends Modals.ModalComponentState {
  code?: string,
  codeCreated?: boolean,
}

interface TemplateCodesProps extends Modals.ModalComponentProps {
  id?: number,
  communityId?: any,
  setSegmentCode?: any,
  communityRecordId?: any,
}
export class TemplateCodesModal extends Modals.GenericModalComponent<TemplateCodesProps, TemplateCodesState> {
  public static key: string = 'templateCodes'
  public static contextType = RasaContext

  constructor(props: Modals.ModalComponentProps) {
    super(props, TemplateCodesModal.key)
    this.state = {
      code: '',
      codeCreated: false,
    }
  }
  protected renderChildren(data: any) {
    return <div>
            <h4 className="label">Enter your segment code</h4>
            <input
              type="text"
              value={this.state.code}
              placeholder="Enter segment code..."
              onChange={(e) => {
                e.preventDefault()
                this.setState({ code: e.target.value })
              }}
            />
    </div>
  }

  protected getSaveText(): string {
    return 'Save'
  }

  protected saveDisabled(): boolean {
    return isEmpty(this.state.code)
      || this.state.code.toLowerCase() === DEFAULT_COMMUNITY_PARTNER_CODES.ALL.valueOf().toLowerCase()
      || this.state.code.toLowerCase() === DEFAULT_COMMUNITY_PARTNER_CODES.NO_CODE.valueOf().toLowerCase()
  }

  protected updateProperty(property, value) {
    this.props.updateModal(TemplateCodesModal.key, {
      name: property,
      value,
    })
  }

  protected doSave(data: any) {
    return this.createOrUpdate(data)
      .then(() => {
      this.setState({})
      this.setSegmentCode(this.state.code)
      this.props.closeModal(TemplateCodesModal.key)
      return Promise.resolve({})
    })
    .catch((error) => {
      return Promise.reject({})
    })
  }

  private setSegmentCode(code: any) {
    this.props.setSegmentCode(code)
  }

  private createOrUpdate(payload: any): Promise<any> {
    return this.context.entityMetadata
    .getEntityObject('communityPartner')
    .then((communityPartnerEntityObject: BaseClientEntity) => {
      communityPartnerEntityObject.setRecordId(this.props.communityRecordId, null)
      communityPartnerEntityObject.data.communityIdentifier = this.props.communityRecordId
      communityPartnerEntityObject.data.code = this.state.code
      return communityPartnerEntityObject.save().then(() => {
        this.context.store.dispatch(Flash.showFlashMessage(SEGMENT_CODE_CREATED_SUCCESSFULLY))
        this.setState({ codeCreated: true })
      }).catch((error) => {
        this.context.store.dispatch(Flash.showFlashError(SEGMENT_CODE_CREATTION_FAILED))
        this.setState({ codeCreated: false })
    })
  })
  }
}

export const AddTemplateCodesModal = connect(
  null,
  {
    push: Router.push,
  },
)(TemplateCodesModal)
