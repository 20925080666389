import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import * as GenericRedux from 'generic/genericRedux'
import { RasaReactComponent } from 'generic/rasaReactComponent'
import isNil from 'lodash/isNil'
import React, {ComponentType} from 'react'
import { Button, Row } from 'reactstrap'
import { subscribersFormatDate } from 'shared_server_client/dates'
import '../../subscriber-details/styles.css'
import { ConnectedComponentClass } from 'react-redux'
import {Fields} from "../../../shared/modals";

type Subscriber = any

interface SubscriberIdProps {
  subscriberId: string,
}

interface SubscriberDetailsState {
  communityId: string,
  communityTimeZone: string,
  drop?: boolean
  editMode: boolean,
  isEditing: boolean,
  email: string,
  first_name: string,
  last_name: string,
}

interface SubscriberDetailProps extends GenericRedux.AllComponentProps<Subscriber> {
  subscriberId: string
  onLoad: any
}

class SubscriberDetailComponent extends RasaReactComponent<SubscriberDetailProps, SubscriberDetailsState> {
  public static contextType = RasaContext

  constructor(props: SubscriberDetailProps) {
    super(props, 'rasaSubscriber')

    this.state = {
      communityId: '',
      communityTimeZone: '',
      drop: false,
      editMode: false,
      isDirty: false,
      isLoading: false,
      isSaving: false,
      isEditing: false,
      email: '',
      first_name: '',
      last_name: '',
    }
  }

  public componentDidMount() {
    this.loadOnRouting(this.props.subscriberId, '/contacts/detail')
    this.context.user.init().then(({ activeCommunity }) => {
        this.setState({
          communityTimeZone:
            activeCommunity.data.company_time_zone || 'US/Central',
        });
      });
  }

  public loadRecord(communityId: string, recordId: any): Promise<boolean> {
    return super.loadRecord(communityId, recordId).then((result) => {
      this.props.onLoad()
      return result
    })
  }

  public render = () => {
    return !this.state.isLoading ?
    <div className="subscriber-details mobile-details">
          <div className="header-left">
            <div className="text">
              <div>
                <h4>
                  {`${this.props.data.first_name || ''} ${this.props.data.last_name || ''}`}
                </h4>
                <div>
                  {this.props.data.email || ''}
                  {this.props.data &&
                  <p>
                    {this.props.data.first_name} is <span><strong>
                      {this.props.data.is_subscribed ? 'Subscribed' : 'Unsubscribed'}</strong></span>
                    <br/>
                    {this.lastActiveDate() &&
                      <strong>
                        Last active on {subscribersFormatDate(this.lastActiveDate(), this.state.communityTimeZone)}
                      </strong>}
                  </p>}
                </div>
              </div>
            </div>
          </div>
          <div className="header-right">
            <div className="mobile-dropdown-container">
              <Button onClick={() => this.setState({drop: !this.state.drop})} className="dropdown-button">
                Actions
              </Button>
              { this.state.drop ?
                <div className="dropdown-content">
                {this.props.data.is_subscribed ?
                  <a onClick={() => {
                      this.propertyChanged('is_subscribed', 0)
                      this.saveRecord(this.props.data.id).then(() => this.setState({drop: !this.state.drop}))
                      }}
                     href="#">Unsubscribe
                  </a>
                  :
                  <a onClick={() => {
                      this.propertyChanged('is_subscribed', 1)
                      this.saveRecord(this.props.data.id).then(() => this.setState({drop: !this.state.drop}))
                    }}
                   href="#">Subscribe
                </a>
                }
              </div>
              : null}
            </div>
          </div>

    </div> :
    <div className="loader-wrapper">
      <div className="balls">
        <Loading size="64" />
      </div>
    </div>
  }

  private lastActiveDate = () => {
    const lastOpen = this.props.data.last_open
    const lastClick = this.props.data.last_click
    if (lastOpen && lastClick) {
      return lastOpen > lastClick ? lastOpen : lastClick
    } else {
      return lastOpen || lastClick || ''
    }
  }

  // private initializeEdit = () => {
  //   if (this.state.editMode) {
  //     this.setState({
  //       editMode: false,
  //       email: '',
  //       first_name: '',
  //       last_name: '',
  //     })
  //   } else {
  //     this.setState({
  //       editMode: true,
  //       email: this.props.data.email,
  //       first_name: this.props.data.first_name,
  //       last_name: this.props.data.last_name,
  //     })
  //   }
  // }
}

const SubscriberDetails: ConnectedComponentClass<ComponentType<SubscriberDetailComponent>, Fields> = GenericRedux.registerNewNestedComponent(
  SubscriberDetailComponent,
  'subscriber_details')

interface Action {
  action_type: string
  created: string
  email_subject?: string
  id: string
  issue_date: string
  title?: string
  url?: string
  useragent?: string
}

interface Topic {
  topic: string
  first_click: string
  last_click: string
}

interface Insights {
  actions: Action[],
  topics: Topic[]
}

type SubscriberInsightsProps = SubscriberIdProps & GenericRedux.AllComponentProps<Insights>

class SubscriberInsightsComponent extends RasaReactComponent<SubscriberInsightsProps> {
  constructor(props) {
    super(props, 'rasaSubscriberInsights')

    this.normalizeActionsData = this.normalizeActionsData.bind(this)
    this.normalizeTopicsData = this.normalizeTopicsData.bind(this)
  }

  public componentDidMount() {
    this.loadOnRouting(this.props.subscriberId, '/contacts/detail')
  }

  public render = () => {
    return <div className="last-activity-feed">
      {/* <h6>Last Activity Feed: </h6>
      {this.state.isLoading ?
        <div className="loader-wrapper">
          <div className="balls">
            <Loading size="64" />
          </div>
        </div>
      :
      <div className="grids-container">
        <div className="rasa-data-grid left">
          <Grid data={this.normalizeActionsData(this.props.data.actions || []).slice(0, 6)}>
            <GridColumn field="action_type" title="Type" width={100}/>
            <GridColumn field="issue_date" title="Newsletter Date" width={150}/>
            <GridColumn field="created" title="Action Date" width={150}/>
            <GridColumn cell={ActivityTextCell} title="Title/Subject" width={250}/>
          </Grid>
        </div>
        <div className="rasa-data-grid left">
          <Grid data={this.normalizeTopicsData(this.props.data.topics || []).slice(0, 8)}>
            <GridColumn field="topic" title="Topic" width={150}/>
            <GridColumn field="first_click" title="Last Click" width={150}/>
            <GridColumn field="last_click" title="First Click" width={150}/>
          </Grid>
        </div>
      </div>} */}

    </div>
  }

  private normalizeActionsData = (actions: Action[] = []) => {
    return actions.map( (action: Action) => {
      return {
        action_type: action.action_type,
        issue_date: subscribersFormatDate(action.issue_date),
        created: subscribersFormatDate(action.created),
        email_subject: action.email_subject.split('|')[0],
        title: action.title,
        url: action.url,
      }
    })
  }

  private normalizeTopicsData = (topics: Topic[] = []) => {
    return topics.map((t: Topic) => {
      return {
        topic: t.topic,
        first_click: subscribersFormatDate(t.first_click),
        last_click: subscribersFormatDate(t.last_click),
      }
    })
  }

}

const SubscriberInsights: ConnectedComponentClass<ComponentType<SubscriberInsightsComponent>, Fields> = GenericRedux.registerNewNestedComponent(
  SubscriberInsightsComponent,
  'subscriber_insights')

interface SubscriberDetailState {
  isLoaded: boolean,
}
export class MobileSubscriberDetailsContainer extends React.Component<any, SubscriberDetailState> {
  public static contextType = RasaContext

  constructor(props) {
    super(props, '')
    this.state = {
      isLoaded: false,
    }
  }

  public render() {
    const guid: string = this.context.store.getState().app.params.id
    return isNil(guid) ? <div></div> :
      <div className="subscriber-details">
        <Row>
          <SubscriberDetails subscriberId={guid} onLoad={() => this.setState({isLoaded: true})}/>
        </Row>
        {this.state.isLoaded &&
          <Row>
            <SubscriberInsights subscriberId={guid}/>
          </Row>}
        {/* <Row>
          <div className="day-activity-feed">
            <h6>
              Time of the Day Activity:
            </h6>
          </div>
        </Row> */}
      </div>
  }
}
