import classnames from 'classnames'
import { DashboardMenuOption } from 'components/dashboard-menu/constants'
import { HeaderComponent } from 'components/header/component'
import { NextIcon } from 'components/icons/next'
import { ImportConsentModal } from 'components/subscriber-grid/modals'
import { RasaContext } from 'context'
import { registerNewComponentWithModals } from 'generic/genericRedux'
import React from 'react'
import * as Modals from 'shared/modals'
import { isOverContactLimit } from 'shared_server_client/types/billing_plan'
import { enterpriseUsage, UsageStats } from 'shared_server_client/types/usage_stats'
import * as Constants from './constants'
import './styles.css'

interface State {
  initialized: boolean,
  usageStats: UsageStats,
}

interface Props extends Modals.ModalActionsProps {
  modals: Modals.ModalStateProps,
  push: any,
}

class ImportContactsComponent extends React.Component<Props, State> {
  public static contextType = RasaContext;

  constructor(props: Props) {
    super(props)
    this.state = {
      initialized: false,
      usageStats: enterpriseUsage,
    }
  }

  public render() {
    return <div className="import-contacts-wrapper">
      <div className="modal-wrapper">
        <ImportConsentModal data={this.props.modals}
                            closeModal={this.props.closeModal}
                            goTo={this.goTo}
                            setSessionModal={this.props.setSessionModal}
                            saveModal={this.props.saveModal}
                            title="Import Consent"/>
      </div>
      <HeaderComponent
        title={'CONTACTS'}
        subTitle={'Import Contacts'}
        description={['Choose the way you would like to bring in your contacts.']}
      />
      { isOverContactLimit(this.state.usageStats) &&
      <div className="warning-message">
        You have exceeded the number of contacts allowed for your plan.&nbsp;
        Please contact our customer support for information on upgrading.
      </div>}
      <div className={classnames('import-contacts', this.canAddContacts() ? 'enabled' : 'disabled')}
          onClick={() => this.handleOnClick(`${DashboardMenuOption.contacts}/import-csv`)}>
        <div className="words">
          <h1>Import Contacts</h1>
          <p>Import contacts with a CSV file.</p>
        </div>
        <div className="nexticon">
          <NextIcon/>
        </div>
      </div>
      <div className={classnames('import-contacts', this.canAddContacts() ? 'enabled' : 'disabled')}
          onClick={() => this.handleOnClick(`${DashboardMenuOption.contacts}/contact-integration`)}>
        <div className="words">
          <h1>Import from third party applications</h1>
          <p>Import contacts from Mailchimp, Hubspot, Shopify, Zapier, Custom Integration Api Key and more.</p>
        </div>
        <div className="nexticon">
          <NextIcon/>
        </div>
      </div>
    </div>
    }

    public componentDidMount() {
      this.context.user.init().then(({person, activeCommunity}) => {
        this.setState({
          initialized: true,
          usageStats: activeCommunity.billingInfo.usageStats,
        });
      })
    }

    private canAddContacts = () => !isOverContactLimit(this.state.usageStats)

    private goTo = (location) => {
      this.props.closeModal(ImportConsentModal.key)
      this.props.push(location)
    }

    private handleOnClick = (location) => {
      if (this.canAddContacts()) {
        if (this.shouldShowModal(location)) {
          this.props.openModal(ImportConsentModal.key, { type: 'yesNo', url: location})
        } else {
          this.goTo(location)
        }
      }
    }

    private shouldShowModal = (url) => {
      if (
        this.props.modals[ImportConsentModal.key].numTimesShowed === 0 ||
        url.includes('csv') && this.props.modals[ImportConsentModal.key].data.consentValue !== true ||
        url.includes('integration') && sessionStorage.getItem(Constants.CONSENT_INTEGRATIONS_IMPORT) !== 'true'
        ) {
        return true
      } else {
        return false
      }
    }
}

export const ImportContacts = registerNewComponentWithModals<any>(
  ImportContactsComponent,
  'import_contacts',
  [ImportConsentModal.key],
  {},
  )
